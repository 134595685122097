.container {
  padding: 20px;
  width: 75%;
  /* margin: 0 auto; */
  text-align: center;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px !important;
}



.cardFreelanceJob h3 {
  margin-bottom: 10px;
  color: #333;
}

.cardFreelanceJob p {
  margin: 5px 0;
  color: #555;
  font-size: 1.05rem;
}

.error {
  color: red;
}

.btn_view {
  background-color: #0056b3;
  color: white;
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #0056b3;
  cursor: pointer;
}

.title {
  text-align: center;
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px !important;
  font-weight: bolder;
}

.addJobLink {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
}

.warningMessage {
  text-align: center !important;
  color: grey !important;
}

.Add_Job_Link {
  margin-top: 20px !important;
  text-transform: none !important;
}

.freelance_opportunities {
  color: #555 !important;
}

.firstContainer {
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-bottom: 20px;
}

.css-1avi6a7-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #f0f0f0 !important;
  /* Smooth transition for color changes */
}

.tabsContainer {
  margin: auto;
  width: 60%;
  margin-bottom: 1.5rem;
}

.infoTabs {
  display: flex !important;
  justify-content: space-evenly !important;
  justify-content: center !important;
  align-items: center !important;
  background: #eceff1 !important;
  padding: 0.5rem !important;
  border-radius: 50px !important;
  gap: 0 !important;
  margin: 2.5rem 0 0 !important;
}

.navLink {
  font-size: 1rem !important;
  width: auto !important;
  padding: 0.65rem !important;
  background: transparent !important;
  text-align: center !important;
  color: #b0bec5 !important;
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;
  transition: none !important;
  white-space: nowrap !important;
  padding-inline: 4rem !important;

}

.activeTab {
  background: #fff !important;
  color: #000 !important;
  font-weight: 700 !important;
  transition: none !important;

}

.activeTabMargin {
  padding-inline: 4rem !important;

}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  /* Spacing between the icon and text */
  vertical-align: middle;
}

.cardFreelanceJob {
  background-color: #FFFFFF;
  margin-bottom: 20px;
  padding: 16px;
  width: 30%;
  /* Adjust width as per your layout */
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-left: 2px solid #0DCAF0;
  border-radius: 6.4px;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.opportunitiesLink {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.opportunitiesLink Button {
  background-color: #198754;
  border-radius: 6.4px;
  color: white;
  padding: 10px 3.5rem;
  text-transform: none;
  /* Prevent all caps */
  transition: background-color 0.3s ease-in-out;
  text-decoration: none !important;
}

.opportunitiesLink Button:hover {
  background-color: #157347;
  /* Darker hover color */
}

.FreelanceJob {
  margin-top: 7rem !important;
}

.statusBadge {
  border: 1px solid #157347;
  width: fit-content;
  padding: 8px;
  border-radius: 11px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;

}

.oppDrobdown {
  display: flex;
  justify-content: end;
}

.CardContent {
  text-align: start;
}

.titleMyOpp {
  margin-bottom: 20px !important;
}

.Url {
  margin-top: 20px !important;
}

.fileUpload {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.btn_edit {
  margin: auto !important;
  display: flex !important;
}

.errorText {
  color: #d32f2f;
}