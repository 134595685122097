.tasksTrackingContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.3rem;
    justify-content: space-around;
    margin: 1.5rem;
}

.taskCard {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.taskCard h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.taskCard p {
    font-size: 1rem;
    margin-bottom: 5px;
}

.taskCard a {
    color: #007bff;
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
}

.taskCard a:hover {
    text-decoration: underline;
}

.linkButton {
    background: none;
    color: #007bff;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    text-decoration: none;
    margin: 0;
    padding: 0;
    padding-block: .3rem;
}

.linkButton:hover {
    text-decoration: underline;
}

.commentSection {
    margin-top: 10px;
    background-color: #eef;
    padding: 10px;
    border-radius: 5px;
}

.commentSection h4 {
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.comment {
    margin-bottom: 8px;
}

.comment p {
    font-size: 0.9rem;
}

.pagination {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-block: 1rem;
}

.pagination button {
    padding: 0.6rem;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 0;
    transition: background-color 0.3s ease;
}

.pagination .active {
    font-weight: bold;
    background-color: #0056b3;
    color: white;
}

.pagination span {
    padding: 0.5rem;
}