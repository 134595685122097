.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  transition: background-color 0.3s ease;
}

.logoContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.logo,
.logo1,
.logo2 {
  object-fit: contain;
  width: 6.5rem;
  transition: transform 0.3s ease;
}



.navLink {
  display: flex;
  gap: 0.75rem;
}

.navLink a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #333;
  padding: 7px;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.navLink a.active {
  color: #ffffff;
  background-color: #007bff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  border-radius: 5px;
}