.profileContainer {
  max-width: 90%;
  width: 100%;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px !important;
  font-weight: bolder;
}

.userInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 20px;
}

.userCard {
  display: flex;
  flex-direction: column;
  padding: 9px;
  width: 30%;
  gap: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userCard p {
  margin: 0;
  color: #555;
  font-size: 1rem;
}

.userCard strong {
  font-weight: bold;
  color: #000;
  font-size: 1.1rem;
}

.platformsSection {
  margin-top: 20px;
}

.platformsSection h3 {
  margin-bottom: 10px;
  color: #333;
}

/* Style for input and select to appear next to each other */
.inputContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.inputField,
.selectField {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputField {
  width: 60%;
  /* Adjust width of input relative to select */
}

.selectField {
  width: 40%;
  /* Adjust width of select relative to input */
}

.addButton {
  display: inline-block;
  padding: 8px 12px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.addButton:hover {
  background-color: #0056b3;
}

.linksSection {
  margin-top: 20px;
  display: flex;
  gap: 1rem;
}

.linksSection h1 {
  padding-top: .2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000;
  font-size: 1.1rem;
  margin-block: auto;
}

.linkList {
  display: flex;
  gap: 1rem;
}

.linkCard {
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 5px;
}

.linkCard a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  word-break: break-word;
}

.linkCard a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.warning {
  color: red;
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

.platformLogo {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
  background-color: #d3d3d3;
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 2px;
  position: relative;
}

.communityLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.communityLogo {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
  background-color: #d3d3d3;
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 1px;
  position: relative;
}

.changePasswordButton {
  margin-top: 20px;
  padding: 10px;
  background-color: #198754;
  /* font-size: 20px; */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 20px !important;
}