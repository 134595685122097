.addWebinarContainer {
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.addWebinarContainer h2,
.addWebinarContainer h3 {
    color: #333;
    text-align: center;
    margin-block: 1rem;
    font-weight: 600;
}

.addWebinarContainer label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
}

.addWebinarContainer input,
.addWebinarContainer select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.addWebinarContainer input:focus,
.addWebinarContainer select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.addWebinarSubmitBtn {
    display: inline-block;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
}

.addWebinarSubmitBtn:hover {
    background-color: #0056b3;
}

.selectedProfile span {
    font-weight: 600;
}

.header {
    display: flex;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    margin-bottom: 1.5rem;
}

.addWebinarBtn {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.addWebinarBtn:hover {
    background-color: #0056b3;
}

.collapsibleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    /* Show the pointer cursor to indicate it's clickable */
    transition: background-color 0.3s ease;
}

.collapsibleHeader:hover {
    background-color: #e0e0e0;
}

.collapsibleTitle {
    font-size: 1.1rem;
    font-weight: bold;
}

.toggleIcon {
    font-weight: bold;
    font-size: 1.1rem;
    margin-left: 10px;
    transition: transform 0.3s ease;
    /* Smooth animation */
}

.toggleIcon.collapsed {
    transform: rotate(90deg);
    /* Rotate the icon when collapsed */
}

.toggleIcon.expanded {
    transform: rotate(0deg);
    /* Reset rotation when expanded */
}

.form {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    animation: slideDown 0.3s ease;
    /* Add animation when expanding */
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.form {
    margin-top: 1rem;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}