* {
    box-sizing: border-box;
}

.profileContainer {
    max-width: 90%;
    width: 100%;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px !important;
    font-weight: bolder;
}

.userInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 20px;
}

.userCard {
    display: flex;
    flex-direction: column;
    padding: 9px;
    width: 30%;
    gap: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userCard p {
    margin: 0;
    color: #555;
    font-size: 1rem;
}

.userCard strong {
    font-weight: bold;
    color: #000;
    font-size: 1.1rem;
}

/* Coaching rounds section */
.coachingRoundsCard {
    display: flex;
    flex-direction: column;
    padding: 9px;
    width: 100%;
    gap: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.coachingRoundsList {
    list-style-type: disc;
    margin-left: 20px;
}

.coachingRoundItem {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
}

.changePasswordButton {
    margin-top: 20px;
    padding: 10px;
    background-color: #198754;
    /* font-size: 20px; */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 20px !important;
}

.createMeetingBtn {
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    /* font-size: 20px; */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 20px !important;

}

/* Ensure modal covers the entire screen */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's above other elements */
}

/* Modal content should be centered and properly visible */
.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1001;
    position: relative;
}

.modalHeader {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #0056b3;
    margin-bottom: 15px;
}


.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.formGroup label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.formGroup input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.meetingSubmitBtn {
    background-color: #198754;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.meetingSubmitBtn:hover {
    background-color: #157347;
}

.popover {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 1rem 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    font-size: 1rem;
    font-weight: bold;
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    10% {
        opacity: 1;
        transform: translateY(0);
    }

    90% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}