.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden; /* Prevent scrolling */
}

.modalContent {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto; /* Enable scrolling inside modal if content is large */
    width: 500px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.closeButton {
    position: absolute;
    top: 1px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.closeButton:hover {
    color: #f00;
}
