.subNavbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 30px;
  background-color: #f0f4f8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  /* margin: 10px 0; */
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.greeting {
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  background-color: #4aac7d;
  padding: 5px 12px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoutButton {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(255, 77, 79, 0.1);
}

.logoutButton:hover {
  background-color: #ff7875;
  box-shadow: 0 6px 12px rgba(255, 77, 79, 0.15);
}

.dropdownMenu {
  border-radius: 8px;
  text-align: right;
  /* Right to left alignment for Arabic */
}