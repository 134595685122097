.freelanceContainer {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
}

.freelanceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.freelanceHead {
    font-size: 1.25rem;
    font-weight: 600;
    margin-block: 0.75rem;
}

.opportunityList {
    margin-top: 20px;
}

.opportunityCard {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.opportunityCard h2 {
    font-size: 1.1rem;
    font-weight: 600;
}

.opportunityCard p {
    margin-block: 0.4rem;
}

.viewMoreLink,
.editButton {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
}

.editButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.editButton:hover {
    background-color: #0056b3;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.editForm label {
    font-weight: bold;
    margin-block: 8px;
    display: block;
}

.editForm input,
.editForm textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.saveButton,
.cancelButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnSection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.saveButton:hover,
.cancelButton:hover {
    background-color: #0056b3;
}

/* Add these styles to Freelance.module.css */
.uploadButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 0;
}

.uploadButton:hover {
    background-color: #0056b3;
}

.fileInput {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.downloadLink {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.downloadLink:hover {
    background-color: #0056b3;
}

.successMessage {
    color: #2e7d32;
    background-color: #c8e6c9;
    border: 1px solid #2e7d32;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.errorMessage {
    color: #d32f2f;
    background-color: #ffcdd2;
    border: 1px solid #f44336;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}