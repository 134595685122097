.coachProfile {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f7f7f7;
}

.coachProfile h1 {
  color: #333;
  margin-bottom: 20px;
}

.coachProfile p {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.feedbackCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.feedbackCard {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.feedbackCard:hover {
  transform: translateY(-5px);
}

.feedbackCard h3 {
  color: #333;
  margin-bottom: 10px;
}

.feedbackCard p {
  color: #777;
  font-size: 0.9rem;
  margin: 5px 0;
}

.feedbackCard p strong {
  color: #333;
}

.infoCard {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedbackWork {
  margin-block: 30px !important;
  font-size: 30px;
  font-weight: bolder;
}

.studentInfo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profileContainer {
  max-width: 90%;
  width: 100%;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.title {
  text-align: center;
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px !important;
  font-weight: bolder;
}