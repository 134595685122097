.meetingListContainer {
    max-width: 800px;
    margin: 1rem auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.meetingCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.meetingCard {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.meetingCard:hover {
    transform: translateY(-5px);
}

.meetingName {
    font-size: 1.25rem;
    color: #007bff;
    margin-bottom: 0.5rem;
}

.meetingInstructor,
.meetingDate,
.meetingTime,
.meetingReserved {
    margin-bottom: 0.5rem;
    color: #555;
}

.meetingLink {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-inline-end: 20px !important;
}

.meetingLink:hover {
    background-color: #0056b3;
}
