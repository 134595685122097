.container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #f1f1f1;
}

.passed {
    text-align: center;
    font-size: 30px;
    font-weight: bolder;

}

.title {
    font-weight: bolder;
    font-size: 25px;
}