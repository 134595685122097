.container {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
}

.title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-block: 20px;
}
.successMessage {
  font-weight: bold;
  color: green;
  /* red for errors */
  background-color: rgba(163, 231, 163, 0.555);
  /* light red background */
  border: 1px solid green;
  /* darker red border */
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
}

.errorMessage {
  color: #d32f2f;
  /* red for errors */
  background-color: #ffcdd2;
  /* light red background */
  border: 1px solid #f44336;
  /* darker red border */
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
}

.section {
  margin-top: 20px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section h2 {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}

.fileInput {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  background-color: #007BFF;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-inline: 20px !important;
}

.button:hover {
  background-color: #0056b3;
}

.downloadLink {
  background-color: #007BFF;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.note {
  font-size: 1rem;
  font-style: italic;
  color: #555;
  margin-left: 10px;
  /* background: #ffff0070; */
  padding: 13px;

}

.message {
  color: #d32f2f;
  /* red for errors */
  background-color: #ffcdd2;
  /* light red background */
  border: 1px solid #f44336;
  /* darker red border */
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
}