.tasksInstructorContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.tasksInstructorContainer h1 {
    font-size: 2rem;
    font-weight: 700;
    padding-block: .75rem;
    color: #0056b3;
}

.taskCard {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: left;
}

.taskCard h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.taskCard p {
    margin: 5px 0;
    font-size: 1.1rem;
    color: #555;
}

.taskCard strong {
    color: #333;
}

.downloadBtn {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

a,
.downloadBtn {
    color: #007bff;
    text-decoration: none;
}

a:hover,
.downloadBtn:hover {
    text-decoration: underline;
}

.commentSection {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.commentInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.gradeInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.submitButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}

.errorText {
    color: red;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 5px;
}