.webinarListContainer {
    max-width: 800px;
    margin: 1rem auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.webinarListContainer h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.webinarCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.webinarCard {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.webinarCard:hover {
    transform: translateY(-5px);
}

.webinarName {
    font-size: 1.25rem;
    color: #007bff;
    margin-bottom: 0.5rem;
}

.webinarProfile,
.webinarDate,
.webinarTime {
    margin-bottom: 0.5rem;
    color: #555;
}

.webinarLink {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-inline-end: 20px !important;
}



.webinarLinkStatus {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-inline-end: 20px !important;
    margin-top: 20px;
}

.webinarLink:hover {
    background-color: #0056b3;
}

.statusDropdown select {
    padding: 5px;
    font-size: 14px;
    margin-top: 10px;
}

.statusDropdown {
    display: flex;

}

.editIcon {
    font-size: 20px;
}

.inputWebinar {
    margin-top: 20px !important;
}

.EditLink {
    display: flex;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-inline-end: 20px !important;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px !important;
    /* width: 20%; */
    text-transform: none !important;


}