.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  padding: 8px 12px;
  font-size: 1rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button.active {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.container {
  width: 90%;
  margin: auto;
}

.EditLink {
  display: flex !important;
  padding: 0.5rem 1rem !important;
  color: #fff !important;
  background-color: #007bff !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  transition: background-color 0.2s ease !important;
  margin-block: 20px !important;
  align-items: center !important;
  justify-content: space-around !important;
  margin-top: 20px !important;
  /* width: 20%; */
  text-transform: none !important;
  font-size: 15px !important;



}