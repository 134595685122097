.meetingInstructorContainer {
    padding: 20px;
    max-width: 1000px;
    /* Made the card bigger */
    margin: 0 auto;
    text-align: center;
}

.title {
    text-align: center;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px !important;
    font-weight: bolder;
}

.section {
    margin-bottom: 40px;
}

.sectionTitle {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.detailsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.detailCard {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 1.2rem;
    /* Increase padding */
    width: 23rem;
    /* Increase card width */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Make the shadow slightly deeper */
    margin: 0.5rem;
    /* Add a little gap */
}

.detailCard h3 {
    margin: 0;
    font-size: 1.4rem;
    /* Increased the font size */
    margin-bottom: 10px;
}

.detailCard p {
    margin: 10px 0;
    /* Added more space between the elements */
    font-size: 1.1rem;
    /* Increased font size */
    color: #333;
}

.detailCard a {
    color: #007bff;
    text-decoration: none;
}

.detailCard a:hover {
    text-decoration: underline;
}

/* Attendee Styles */
.attendanceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    /* Add a bottom border */
}

.attendanceEmail {
    font-weight: bold;
    color: #0056b3;
    flex: 2;
}

.attendanceStatus {
    flex: 1;
    color: #333;
    text-align: center;
}

.attendanceButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 0.6rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

}

.attendanceButton:hover {
    background-color: #0056b3;
}

.attendancesTitle {
    font-weight: 600;
    color: black;
}

.buttonGroup {
    margin-top: 10px;
    display: flex;
    gap: .3rem;
}

.attendanceButton {
    margin-right: 10px;
    padding: 8px 12px;
    background-color: #198754 !important;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.attendanceButton:hover {
    background-color: #157347 !important;
}

.attendanceStatus {
    font-weight: bold;
    margin-right: 10px;
}

.deleteButton {
    margin-top: 10px;
    background-color: red;
    color: white;
}

.deleteButton:hover {
    background-color: darkred;
}

.radioButtons {
    display: flex;
    justify-content: space-around !important;
}