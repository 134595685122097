.freelanceJobsTableContainer {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.tableWrapper {
    display: flex;
    justify-content: center;
}

/* Heading styles */
.freelanceJobsTableContainer h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* Error message styles */
.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

/* Table styles */
.table {
    border-collapse: collapse;
    margin: 0 auto 20px auto;
}

.table th,
.table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tbody tr:hover {
    background-color: #e9ecef;
}

/* Join button styles */
.joinButton {
    display: inline-block;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.joinButton:hover {
    background-color: #0056b3;
}

/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.pagination button {
    padding: 8px 12px;
    font-size: 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button.active {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Approve and Reject button styles */
/* Approve and Reject button styles */
.approveButton {
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.approveButton:hover {
    background-color: #218838;
}

.rejectButton {
    padding: 8px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.rejectButton:hover {
    background-color: #c82333;
}

/* Confirmation dialog styles */
.confirmDialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmDialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.confirmActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirmButton,
.cancelButton {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirmButton {
    background-color: #007bff;
    color: white;
}

.cancelButton {
    background-color: #6c757d;
    color: white;
}

/* File Download Button */
.downloadBtn {
    padding: 8px 12px;
    background-color: #17a2b8;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.downloadBtn:hover {
    background-color: #138496;
}