.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.tabsContainer {
    margin: auto;
    width: 60%;
    margin-bottom: 1.5rem;
}

.infoTabs {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    background: #eceff1 !important;
    padding: 0.5rem !important;
    border-radius: 50px !important;
    gap: 0 !important;
    margin: 2.5rem 0 0 !important;
}

.navLink {
    font-size: 15px !important;
    width: auto !important;
    padding: 0.5rem 0.75rem !important;
    background: transparent !important;
    text-align: center !important;
    color: #b0bec5 !important;
    border-radius: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: none !important;
    transition: none !important;
    white-space: nowrap !important;
}

.activeTab {
    background: #fff !important;
    color: #000 !important;
    font-weight: 700 !important;
    transition: none !important;
}

.card {
    background-color: #FFFFFF;
    margin-bottom: 20px;
    padding: 16px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-left: 2px solid #0DCAF0;
    border-radius: 6.4px;
    text-align: left;
}

.card h2 {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.5rem;
}

.card p {
    margin: 5px 0;
    font-size: 1.1rem;
    color: #555;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.submitForm {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.fileInput,
.urlInput {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.submitButton {
    background-color: #198754;
    color: white;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 6.4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
    width: 50%;
}

.submitButton:hover {
    background-color: #157347;
}

.reviewSection {
    display: flex;
    justify-content: space-between;
    /* align-items: center;  */
    margin-top: 10px;
    padding: 20px;  
    background-color: #FAFBFD;
    border-radius: 6.4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reviewComment, .reviewDegree {
    flex: 1; 
    /* text-align: center;  */
}

.lineBreak {
    width: 1px;
    height: 6rem; 
    background-color: #C7C7C7; 
    margin: 0 20px; 
}

.reviewHeader {
    font-size: 1.1rem;  
    color: #333;
    font-weight: bold;
    margin-bottom: 10px; 
}

.reviewSection p {
    color: #000000 !important;
    font-weight: 400 !important;
}

.degreeValue {
    font-size: 5rem !important;  
    color: #343A40 !important;
    font-weight: bold !important;
}

.errorText {
    color: red;
    font-size: 1rem;
    font-weight: 600;
}

.fileHint {
    font-size: 0.875rem;
    /* Slightly smaller font size */
    color: #555;
    margin-top: 5px;
}