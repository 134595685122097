/* WebinarAttendance.module.css */
.webinarList {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.buttonSubmit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.submitButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #45a049;
}

.submitButton:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.note {
  font-size: 1rem;
  font-style: italic;
  color: #555;
  margin-left: 10px;
  background: #ffff0070;
  padding: 13px;

}