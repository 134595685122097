/* StudentDashboard.module.css */

.container {
  margin: 0 auto;
  padding: 20px;
  /* max-width: 1200px; */
  border-radius: 8px;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

input[type='text'] {
  padding: 10px;
  font-size: 1rem;

  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

input[type='text']:focus {
  border-color: #007bff;
}

select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

select:focus {
  border-color: #007bff;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
}

table th,
table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tbody tr:hover {
  background-color: #e9ecef;
}

.pagination {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.6rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 0;
  transition: background-color 0.3s ease;
}

.pagination .active {
  font-weight: bold;
  background-color: #0056b3;
  color: white;
}

.pagination span {
  padding: 0.5rem;
}

.chartTitle {
  text-align: center;
  font-size: 25px;
  margin-block: 20px !important;

}

.MeetingCharts {
  width: 50%;
  margin-block: 20px !important;
}

.TasksCharts {
  width: 50%;
  margin-block: 20px !important;
  /* border-left: 1px solid blue; */


}

.chart {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 50px;

}


.tableAndFilters {
  display: flex;
}

.tableAndFilters {
  display: flex;
  justify-content: space-between;
}

.filter {
  width: 300px;
}

.controls {
  margin-bottom: 20px;
}

.pagination {
  margin-top: 20px;
}

.FiltersButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.searchInput {
  width: 30%;
}

.divTextField {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.divTextField p {
  color: #0056b3 !important;
}