.unauthorizedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    background-color: #f5f5f5;
    color: #333;
}

.unauthorizedContainer h2 {
    font-size: 2.5rem;
    color: #d9534f;
    margin-bottom: 20px;
}

.unauthorizedContainer p {
    font-size: 1.2rem;
    color: #555;
}