.webinarContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.title {
    text-align: center;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px !important;
    font-weight: bolder;
}

.webinarCard {
    background-color: #FFFFFF;
    margin: auto;
    margin-bottom: 20px;
    padding: 1.5rem;
    width: 75%;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-left: 2px solid #0DCAF0;
    border-radius: 6.4px;
    transition: none;
}

.webinarInfo {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #555;
    margin: 5px 0;
}

.webinarInfo strong {
    margin-right: .3rem;
}

.webinarCard h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.webinarCard p {
    display: flex;
    margin: 0.5rem 0;
    font-size: 1.1rem;
    color: #555;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    /* Space between icon and text */
}

.webinarLink {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 4rem;
    background-color: #198754;
    /* Button color similar to meetingCard */
    color: white;
    border-radius: 10.14px;
    /* Match the button border-radius */
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
    border: none;
    cursor: pointer;
}

.webinarLink:hover {
    background-color: #157347;
    /* Darker shade for hover */
}
