.meetingContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.title {
    text-align: center;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px !important;
    font-weight: bold;
}

.meetingTitle {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.centeredText {
    margin-bottom: 20px;
}

.boldText {
    font-weight: bold;
    margin: 5px 0;
    font-size: 1.2rem;
}

.detailsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.detailCard {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    width: 200px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.detailCard h3 {
    margin: 0;
    font-size: 1.1rem;
}

.detailCard p {
    margin: 5px 0 0;
    font-size: 1rem;
    color: #333;
}

.detailCard a {
    color: #007bff;
    text-decoration: none;
    word-break: break-all;
}

.detailCard a:hover {
    text-decoration: underline;
}

.tabContainer {
    margin-bottom: 20px;
    border-bottom: 2px solid #e0e0e0;
}

.meetingCard {
    margin-bottom: 20px;
    padding: 0px;
    background-color: #FFFFFF;
    border: 0px, 0px, 0px, 2px;
    box-shadow: 4px 4px 4px 0px #00000040;
    width: 25%;
    border-left: 2px solid #0DCAF0
}

.reserveBtnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reserveBtn {
    background-color: #198754 !important;
    border-radius: 6.4px !important;
    color: white !important;
    padding: 8px 16px;
    text-transform: none;
    margin-top: .5rem !important;
}

.reserveBtn:hover {
    background-color: #157347 !important;
}

.reserveBtn.disabled {
    background-color: #d3d3d3 !important;
    color: #6c757d !important;
    cursor: not-allowed !important;
}

.feedbackBtn {
    background-color: #FD7E14 !important;
    border-radius: 6.4px !important;
    color: white !important;
    padding: 8px 16px;
    text-transform: none;
    margin-top: .5rem !important;
}

.feedbackBtn:hover {
    background-color: #e66f12 !important;
}

.feedbackBtn.disabled {
    background-color: #d3d3d3 !important;
    color: #6c757d !important;
    cursor: not-allowed !important;
}

title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1976d2;
}

.meetingTitle {
    font-size: 1.125rem !important;
    text-align: center !important;
    color: #343A40 !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
}

.subtitle {
    font-size: 1.1rem;
    color: #555;
}

.subtitle strong {
    color: #151F25 !important;
    font-weight: 600 !important;
}

.box {
    display: flex;
    gap: 20px !important;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tabsContainer {
    margin: auto;
    width: 60%;
    margin-bottom: 1.5rem;
}

.infoTabs {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    background: #eceff1 !important;
    padding: 0.5rem !important;
    border-radius: 50px !important;
    gap: 0 !important;
    margin: 2.5rem 0 0 !important;
}

.navLink {
    font-size: 14px !important;
    width: auto !important;
    padding: 0.5rem 0.75rem !important;
    background: transparent !important;
    text-align: center !important;
    color: #b0bec5 !important;
    border-radius: 50px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: none !important;
    transition: none !important;
    white-space: nowrap !important;
}

.activeTab {
    background: #fff !important;
    color: #000 !important;
    font-weight: 700 !important;
    transition: none !important;
}

.noSessionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    width: 60%;
    text-align: center;
    margin: auto;
}

.noSessionsMessage {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1976d2;
    margin: 0;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.timeGap {
    margin: 0 5px;
}