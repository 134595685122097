/* Container for the whole component */
.profileContainer {
    max-width: 90%;
    width: 100%;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Title */
.title {
    text-align: center;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 25px !important;
    font-weight: bolder;
}

/* Student information container */
.studentInfo {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Individual info cards */
.infoCard {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Text inside info cards */
.infoCard p {
    margin: 0;
    color: #555;
    font-size: 1rem;
}

/* Sections for tasks, meetings, webinars, freelance jobs */
.tasksSection,
.meetingsSection,
.webinarsSection,
.freelanceSection,
.freelanceProfileLinksSection {
    margin-top: 20px;
}

/* Section titles */
h3 {
    margin-bottom: 10px;
    color: #333;
}

/* List styles for tasks, meetings, webinars, freelance jobs */
.taskList,
.meetingList,
.webinarList,
.freelanceList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.linkList {
    list-style: none;
    padding: 0;
    display: flex;
    /* flex-direction: column; */
    gap: 1rem;
}

/* Individual cards for tasks, meetings, webinars, freelance jobs */
.taskCard,
.meetingCard,
.webinarCard,
.freelanceCard {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.linkCard {
    display: flex;
    padding: 12px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Text inside task, meeting, webinar, freelance cards */
.taskCard p,
.meetingCard p,
.webinarCard p,
.freelanceCard p {
    margin: 5px 0;
    color: #ee9696;
    font-size: 1rem;
}

/* Strong text */
strong {
    font-weight: 600 !important;
    color: #000;
    font-size: 1.1rem !important;
}

/* Section headers */
.tasksSection h3,
.meetingsSection h3,
.webinarsSection h3,
.freelanceSection h3,
.freelanceProfileLinksSection h3 {
    font-size: 25px;
    color: #0056b3;
    font-weight: bolder;
    margin-block: 20px !important;
}

/* Freelance profile link styles */
.linkList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.linkCard {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platformLogo {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.downloadBtn {
    background-color: #198754;
    color: white;
    padding: 7px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: background-color 0.3s ease;
    margin-left: .5rem;
}

.downloadBtn:hover {
    background-color: #157347;
}

.downloadBtn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.3);
}