.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #ffffff, #f0f0f0);
    font-family: 'Jost', sans-serif;
}

.loginForm {
    width: 350px;
    background: url('../../assets/loginBG.svg') no-repeat center/cover;
    border-radius: 1.2rem;
    padding: 1.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: .8s ease-in-out;
}

.logoContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.logo1 {
    width: 45%;
    object-fit: contain;
}

.logo2 {
    width: 30%;
    object-fit: contain;
}

.loginForm h1 {
    color: #0056b3;
    text-align: center;
    font-size: 2.3em;
    margin-bottom: 50px;
    font-weight: bold;
}

.loginForm h2 {
    color: black;
    font-size: 1.2em;
    margin-bottom: 10px;
}

.loginForm input {
    width: 100%;
    height: 0.5rem;
    margin-block: .8rem;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.loginForm button {
    width: 100%;
    height: 40px;
    color: #fff;
    background: #449f71;
    font-size: 1em;
    font-weight: bold;
    margin-top: 30px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in;
    cursor: pointer;
}

.togglePassword {
    margin: 0 !important;
}

.test {
    width: 100% !important;
}

.inputLabel {
    margin-top: .5rem;
}

.loginForm button:hover {
    background: #226d47;
}