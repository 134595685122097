.dashboard {
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 200px;
}

.link {
    margin-top: 10px;
    display: inline-block;
    color: #007bff;
}

.count {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.chartsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.chartItem {
    flex: 1 1 45%;
    min-width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chartComponent {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lastUpdated {
    font-size: 16px;
    color: rgb(128, 128, 128);
    text-align: center;
    margin-bottom: 20px;
}