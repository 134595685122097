.container {
  margin: 0 auto;
  padding: 20px;
  /* max-width: 1200px; */
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

input[type='text'] {
  padding: 10px;
  font-size: 1rem;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  outline: none;
}

input[type='text']:focus {
  border-color: #007bff;
}

select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

select:focus {
  border-color: #007bff;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tbody tr:hover {
  background-color: #e9ecef;
}

.createMeetingBtn {
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 600;
}

.createMeetingBtn:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.6rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 0;
  transition: background-color 0.3s ease;
}

.pagination .active {
  font-weight: bold;
  background-color: #0056b3;
  color: white;
}

.pagination span {
  padding: 0.5rem;
}

.modalHeader {
  font-size: 1.3rem;
  font-weight: 600;
  margin-block: 0.75rem;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.6rem;
  color: #333;
  font-size: 1rem;
}

.formGroup input,
.formGroup select {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;
}

.formGroup input:focus,
.formGroup select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.meetingSubmitBtn {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.meetingSubmitBtn:hover {
  background-color: #0056b3;
}

.popover {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  font-size: 1rem;
  font-weight: bold;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.dateFilterContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filterLabel {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.dateInputs {
  display: flex;
  gap: 10px;
}

.dateInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 180px;
}