.dashboard {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #2c3b41;
  color: white;
  padding: 20px;
  /* position: fixed; */
  min-height: 74.2vh !important;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  position: fixed;
  width: 15%;
}

.sidebar li {
  padding: 10px;
  border-bottom: 1px solid #394a50;
  /* Lighter line for separation */
}

.sidebar li:hover {
  background-color: #1e282c;
  /* Hover effect */
  cursor: pointer;
}

.mainContent {
  flex-grow: 1;
  /* Takes up the remaining space */
  /* padding: 20px; */
  background-color: #ecf0f5;
  /* Light background color */
}

.mainContent h1 {
  color: #333;
  /* Dark text color for contrast */
}

.activeLink {
  color: white;
  /* Highlight color for active links */
  background-color: #ecf0f5;
}