.editStudentContainer {
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.editStudentContainer h2 {
    color: #333;
    text-align: center;
    margin-block: 1rem;
    font-weight: 600;
}

.form {
    display: flex;
    flex-direction: column;
}

.editStudentContainer label {
    display: block;
    margin-block: 8px;
    color: #555;
    font-weight: bold;
}

.editStudentContainer input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.editStudentContainer input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.fetchButton {
    display: inline-block;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
    margin-bottom: 20px;
}

.fetchButton:hover {
    background-color: #0056b3;
}

.updateButton {
    display: inline-block;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.updateButton:hover {
    background-color: #218838;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.success {
    color: green;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.studentDetails {
    margin-top: 20px;
    background-color: #e9ecef;
    padding: 15px;
    border-radius: 5px;
}

.studentDetails p {
    margin: 8px 0;
}

.editInput {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.studentDetails h3 {
    color: #333;
    margin-bottom: 15px;
    font-weight: 600;
}
