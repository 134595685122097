.addFreelanceJobContainer {
    max-width: 600px;
    margin: 1rem auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.addFreelanceJobContainer h2 {
    color: #333;
    text-align: center;
    margin-block: 1rem;
    font-weight: 600;
}

.addFreelanceJobContainer h4 {
    color: #333;
    text-align: center;
    margin-block: 1rem;
    font-weight: 600;
}

.addFreelanceJobContainer label {
    display: block;
    margin-block: 8px;
    color: #555;
    font-weight: bold;
}

.addFreelanceJobContainer input,
.addFreelanceJobContainer select,
.addFreelanceJobContainer textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.addFreelanceJobContainer input:focus,
.addFreelanceJobContainer select:focus,
.addFreelanceJobContainer textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.addFreelanceSubmitBtn {
    display: inline-block;
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
}

.addFreelanceSubmitBtn:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.collapsibleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.collapsibleHeader:hover {
    background-color: #e0e0e0;
}

.collapsibleTitle {
    font-size: 1.1rem;
    font-weight: bold;
}

.toggleIcon {
    font-weight: bold;
    font-size: 1.1rem;
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.addCategoryBtn {
    margin-top: 10px;
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addCategoryBtn:hover {
    background-color: #218838;
}