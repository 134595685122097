

.tabsContainer {
  margin: auto;
  width: 25%;
  margin-bottom: 1.5rem;
}

.infoTabs {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  background: #eceff1 !important;
  padding: 0.5rem !important;
  border-radius: 50px !important;
  gap: 0 !important;
  margin: 2.5rem 0 0 !important;
}

.navLink {
  font-size: 1.05rem !important;
  width: auto !important;
  padding: 0.5rem 0.75rem !important;
  background: transparent !important;
  text-align: center !important;
  color: #b0bec5 !important;
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;
  transition: none !important;
  white-space: nowrap !important;
}

.activeTab {
  background: #fff !important;
  color: #000 !important;
  font-weight: 700 !important;
  transition: none !important;
}


