.footerContainer {
    background-color: #1c1c1e;
    /* padding: 0.5rem; */
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../assets/footerBG.png");
    background-size: cover; /* Ensure the image covers the entire background */
    background-position: center;
    background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.footerContent {
    text-align: center;
    margin-block: 1rem;
}

.footerText {
    font-size: 1.1rem;
    line-height: 1.7;
    width: 70%;
    margin: auto;
    /* margin-bottom: 1.2rem !important; */
    font-weight: 500;
    color: #FFFFFF !important;
}

.emailContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.emailIcon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.emailLink {
    color: #FAFAFA;
    font-size: 1.1rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease;
}

.emailLink:hover {
    color: #00d1e7;
}

.footerBottom {
    /* margin-top: 20px; */
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Aligns the content to the far right */
}

.footerBottom h3 {
    font-weight: 500;
    color: #FFFFFF;
    font-size: 1rem;
    margin-right: 1rem; /* Adds some padding to the right side */
}
