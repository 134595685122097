.addMeetingContainer {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1,
.modalUploadHeader {
    font-size: 1.2rem;
    font-weight: 600;
}

.uploadButton {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0;
}

.uploadButton:hover {
    background-color: #0056b3;
}

.uploadSection {
    margin-top: 20px;
}

.fileInput {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.downloadLink {
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnSection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

/* Success message styling */
.successMessage {
    color: #2e7d32;
    background-color: #c8e6c9;
    border: 1px solid #2e7d32;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

/* Error message styling */
.errorMessage {
    color: #d32f2f;
    background-color: #ffcdd2;
    border: 1px solid #f44336;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}